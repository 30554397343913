import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { updateLowStockSettings } from "../services/settingService";
import "../App.css";
import IconSelect from "./IconSelect";

const StockRange = ({
  datas,
  setDatas,
  shop,
  setLessStock,
  setMoreStock,
  lessStock,
  moreStock,
  toggle,
  otherInventory,
  setToggle,
}) => {
  const colorInputRefs = useRef(datas.map(() => null));
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleToggleChange = (index) => {
    setDatas((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, toggle: !item.toggle } : item
      )
    );
  };

  const handleInputChange = (index, field, value) => {
    setDatas((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    setTimeout(async () => {
      const formattedData = datas.map((item) => ({
        value:
          item.stock === 1
            ? item.value
            : item.stocklevel === "stocklevelless"
              ? item.value
              : item.stocklevel === "stocklevelmore"
                ? item.value
                : 0,
        status: item.toggle === true ? 1 : 0,
        message:
          item.stocklevel === "stocklevelless"
            ? `${item.premessage || ""} # ${item.postmessage || ""}`
            : item.message || "",
        color: item.color || "",
        size: item.size ? item.size.toString() : "",
        shop_url: shop,
        icon: item.icon,
      }));
      console.log("ss", formattedData);
      try {
        const response = await updateLowStockSettings(formattedData);
        console.log("Data submitted successfully:", response.data);
        setSuccessMessage("Data submitted successfully!");
      } catch (error) {
        console.error("Error submitting data:", error);
        setErrorMessage("Error submitting data. Please try again.");
      } finally {
        setLoading(false);
      }
    }, 2000);
  };
  return (
    <>
    <div style={{ border:"3px solid #F2F2F7", padding:"10px" }}>
      <h4 style={{ color: "#007f61", fontWeight: "600", fontSize: "20px" }}>Low Stock Counter</h4>
      <span>The System allows you to change the stock level according to inventory count.</span>
      </div>
      {datas.map((value, index) => (
        <div className="container" key={index} style={{
          display: otherInventory &&
            (value.stocklevel === "stocklevelone" ||
              value.stocklevel === "stocklevelmore" ||
              value.stocklevel === "stockleveloutofscope" ||
              value.stocklevel === "stocklevelzero")
            ? "none"
            : ""
        }}>
          <div className="box" style={{
            width: "7%"
          }}>
            <p className="on-off-label">
              {value.toggle == true ? "On" : "Off"}
            </p>
            <label className="switch">
              <input
                type="checkbox"
                checked={value?.toggle}
                onChange={() => handleToggleChange(index)}
              />
              <span className="slider round"></span>
            </label>
          </div>

          <div className="box"
            style={{
              width: "13%",
              display: otherInventory
                ? "none"
                : ""
            }}
          >
            <span>
              Stock Level</span>
            <span>
              {value.stock}
            </span>


            {value.stocklevel === "stocklevelless" && (
              <input
                type="number"
                value={value.value}
                onChange={(e) =>
                  handleInputChange(index, "value", e.target.value)
                }


              />
            )}
            {value.stocklevel === "stocklevelmore" && (
              <input
                type="number"
                value={value.value}
                disabled={value.toggle == false}
                onChange={(e) =>
                  handleInputChange(index, "value", e.target.value)
                }
              />
            )}
          </div>

          <div className="box" style={{
            width: "13%",
            marginTop: "15px"
          }}>
            <p>Icon</p>
            <IconSelect
              icon={value.icon}
              handleIconChange={(icon) =>
                handleInputChange(index, "icon", icon)
              }
            />
          </div>

          <div className="box" style={{
            width: "33%",
            marginTop: "15px"
          }}>
            <p>
              <span dangerouslySetInnerHTML={{ __html: value.text }} />
            </p>
            {value.stocklevel === "stocklevelless" && (
              <div>
                <input
                  type="text"
                  size="10"
				style={{
            width: "80px"
				 }}
                  value={value.premessage}
                  onChange={(e) =>
                    handleInputChange(index, "premessage", e.target.value)
                  }
                />
                <input style={{
            marginLeft: "2px",
			 width: "30px"
          }} type="text" value="#" size="1" disabled
		 
		  />{" "}
                <input
                  type="text"
                  size="10"
style={{
            width: "100px"
				 }}
                  value={value.postmessage}
                  onChange={(e) =>
                    handleInputChange(index, "postmessage", e.target.value)
                  }
                />{" "}
              </div>
            )}
            {value.stocklevel !== "stocklevelless" && (
              <input
                type="text"
                value={value.message}
                onChange={(e) =>
                  handleInputChange(index, "message", e.target.value)
                }
              />
            )}
          </div>

          <div className="box" style={{
            width: "10%"
          }}>
            <p>Color</p>
            <div className="color" style={{  borderRadius: "0.5rem" }}>
              <input
                type="color"
                value={value.color}
                style={{ display: "none" }}
                ref={(el) => (colorInputRefs.current[index] = el)}
                onChange={(e) =>
                  handleInputChange(index, "color", e.target.value)
                }
              />
              <span
                className="color-box"
                style={{ backgroundColor: value.color, cursor: "pointer" }}
                onClick={() => colorInputRefs.current[index]?.click()}
              ></span>
              <span>
                <input
                  type="text"
                  style={{ width: "100%" }}
                  value={value.color}
                  onChange={(e) =>
                    handleInputChange(index, "color", e.target.value)
                  }
                />
              </span>
            </div>
          </div>

          <div className="box" style={{ width: "13%" }}>
            <p>Size</p>
            <input
              type="number"
              value={value.size}
              onChange={(e) =>
                handleInputChange(index, "size", Number(e.target.value))
              }
            />
          </div>
        </div>
      ))}

      <div style={{
            textAlign: "right"
          }}>
        <button
          className="primary"
          style={{
            width: "10rem",
            borderRadius: "0.5rem",
            backgroundColor: "#047857",
            padding: "0.5rem",
            color: "#ffff",
          }}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit"}
        </button>
      </div>

      {loading && <p className="loading">Loading...</p>}

      {successMessage && <p className="success-message">{successMessage}</p>}

      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </>
  );
};

export default StockRange;
