import React, { useState, useRef, useEffect } from "react";
import Product from "../modals/Product";
import Collection from "../modals/Collection";
import IconSelect from "./IconSelect";
import axios from "axios";

const FakeStock = ({ shop }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const backendApi = process.env.REACT_APP_BACKEND_APP_HOST;
  const [formData, setFormData] = useState({
    productChecked: "",
    collectionChecked: "",
    widgetDisplay: "",
    icon: "",
    shop_url: shop,
    products: [],
    collections: [],
    fakestock_message: "",
  });

  const stripQuotes = (value) => value.replace(/^"|"$/g, "");

  const handleStoreData = (selectedProductIds) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      products: JSON.parse(selectedProductIds),
    }));
    setIsOpen(false);
  };

  const handleCollectionStoreData = (selectedCollectionIds) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      collections: JSON.parse(selectedCollectionIds),
    }));
    setIsOpen2(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${backendApi}/api/fake_stocks/?shop=${shop}`
        );
        const data = await response.json();
        setFormData((prevFormData) => ({
          ...prevFormData,
          icon:
            data.icon || prevFormData.icon,
          fakestock_message:
            data.fakestock_message || prevFormData.fakestock_message,
          productChecked: data.productChecked || prevFormData.productChecked,
          collectionChecked:
            data.collectionChecked || prevFormData.collectionChecked,
          widgetDisplay: data.widgetDisplay || prevFormData.widgetDisplay,
          products: data.products || prevFormData.products,
          collections: data.collections || prevFormData.collections,
          shop_url: data.shop_url || prevFormData.shop_url,
        }));
      } catch (error) {
        console.error("Error retrieving data:", error);
      }
    };

    fetchData();
  }, [shop]);

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const response = await axios.post(
        `${backendApi}/api/fake_stocks`,
        formData
      );
      setSuccessMessage(response.data.message);
      window.location.reload();
    } catch (error) {
      console.error("Error submitting data:", error);
      setErrorMessage("Error submitting data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
   
<div style={{ border:"3px solid #F2F2F7", padding:"10px" }}>
      <h4 style={{ color: "#007f61", fontWeight: "600", fontSize: "20px" }}>Fake Stock On</h4>
      <span>The System allows you to change the Fake stock settings.</span>
      </div>
  

      <div className="rang-stock-level">
        <span>Fake Stock Message</span>
        <br />

        <textarea
        style={{
          height: "70px",
          width: "200px"
        }}
          name="fakestock_message"
          onChange={(e) =>
            handleInputChange("fakestock_message", e.target.value)
          }
          value={formData.fakestock_message}
        />
      </div>

      <div className="rang-stock-level">
        <span>Fake Stock Icon</span>
        <br />

        <IconSelect
              icon={formData.icon}
              handleIconChange={(icon) =>
                handleInputChange( "icon", icon)
              }
         />
       
      </div>

      <div className="widget-row">
        <div>
          <h5 className="min-heading">Display Fake Stock On:</h5>
          <select
            className="select-like-input"
            value={formData.widgetDisplay}
            onChange={(e) => handleInputChange("widgetDisplay", e.target.value)}
          >
            <option value="">All Products And Collection</option>
            <option value="Products">Choose by Products</option>
            <option value="Collections">Choose by Collections</option>
          </select>
        </div>
      </div>

      {formData.widgetDisplay === "Products" ? (
        <div className="rang-stock-level" style={{ marginTop: "20px" }}>
          <label>
            <input
              type="radio"
              name="productFilter"
              value="include"
              checked={stripQuotes(formData.productChecked) === "include"}
              onClick={() => {
                setIsOpen(true);
                handleInputChange("productChecked", "include");
              }}
            />
            Include Selected Product
          </label>
          <label style={{ marginLeft: "30px" }}>
            <input
              type="radio"
              name="productFilter"
              value="exclude"
              checked={stripQuotes(formData.productChecked) === "exclude"}
              onClick={() => {
                setIsOpen(true);
                handleInputChange("productChecked", "exclude");
              }}
            />
            Exclude Selected Product
          </label>
        </div>
      ) : formData.widgetDisplay === "Collections" ? (
        <div className="rang-stock-level" style={{ marginTop: "20px" }}>
          <label>
            <input
              type="radio"
              name="collectionFilter"
              value="include"
              checked={stripQuotes(formData.collectionChecked) === "include"}
              onClick={() => {
                setIsOpen2(true);
                handleInputChange("collectionChecked", "include");
              }}
            />
            Include Selected Collection
          </label>
          <label style={{ marginLeft: "30px" }}>
            <input
              type="radio"
              name="collectionFilter"
              value="exclude"
              checked={stripQuotes(formData.collectionChecked) === "exclude"}
              onClick={() => {
                setIsOpen2(true);
                handleInputChange("collectionChecked", "exclude");
              }}
            />
            Exclude Selected Collection
          </label>
        </div>
      ) : null}

      <div style={{ marginTop: "30px", textAlign: "right" }}>
        <button
          className="primary"
          style={{
            width: "8rem",
            borderRadius: "0.5rem",
            backgroundColor: "#047857",
            padding: "0.5rem",
            color: "#fff",
          }}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit"}
        </button>
      </div>

      {loading && <p className="loading">Loading...</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}

      {isOpen && (
        <Product
          set={setIsOpen}
          shop={shop}
          onStoreData={handleStoreData}
          data={formData.products}
        />
      )}
      {isOpen2 && (
        <Collection
          set={setIsOpen2}
          shop={shop}
          onStoreData={handleCollectionStoreData}
          data={formData.collections}
        />
      )}
    </>
  );
};

export default FakeStock;
