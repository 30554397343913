import React, { useState, useEffect } from "react";
import {
  XSmallIcon
} from '@shopify/polaris-icons';
import { Icon, AppProvider } from '@shopify/polaris';
import { Button } from '@shopify/polaris';
import { fetchCollections } from "../services/shopifyService";

const Collection = ({ set, shop, onStoreData, data }) => {
  const [collections, setCollections] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [selectedCollectionIds, setSelectedCollectionIds] = useState(new Set());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCollections(shop, keyword);
        setCollections(response.collections);
        console.log("Data retrieved successfully:", response.collections);
      } catch (error) {
        console.error("Error retrieving data:", error);
      }
    };

    fetchData();
  }, [shop, keyword]);

  useEffect(() => {
    console.log("Data prop received:", data);

    try {
      if (data) {
        const parsedData = JSON.parse(data);
        if (Array.isArray(parsedData)) {
          const productIdsSet = new Set(parsedData.map((id) => String(id)));
          setSelectedCollectionIds(productIdsSet);
        } else {
          console.warn("Parsed data is not an array:", parsedData);
          setSelectedCollectionIds(new Set());
        }
      }
    } catch (error) {
      console.error("Error parsing JSON data:", error);
    }
  }, [data]);

  console.log("c", data);

  const handleInputChange = (value) => {
    setKeyword(value);
  };

  const handleCheckboxChange = (id) => {
    setSelectedCollectionIds((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const handleStoreData = () => {
    setLoading(true);

    const selectedProductIdsArray = Array.from(selectedCollectionIds);
    const jsonPayload = JSON.stringify(selectedProductIdsArray);
    if (onStoreData) {
      onStoreData(jsonPayload);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <AppProvider>
      <div className="modal-container">
        <div className="modal-middle">
          <div className="modal-header">
            <h4>Add Collection</h4>
            <span onClick={() => set(false)}>
              <Icon
                source={XSmallIcon}
                tone="base"
              />
            </span>
          </div>
          <div className="search-container">
            <input
              type="search"
              name="keyword"
              onChange={(e) => handleInputChange(e.target.value)}
              placeholder="Search collections"
            />
          </div>
          <div className="product-list">
            {collections.length > 0 ? (
              collections.map((collection) => (
                <div key={collection.id} className="search-row">
                  <input
                    type="checkbox"
                    id={collection.id}
                    checked={selectedCollectionIds.has(String(collection.id))}
                    onChange={() => handleCheckboxChange(String(collection.id))}
                  />
                  <label htmlFor={collection.id}>{collection.title}</label>
                </div>
              ))
            ) : (
              <p style={{ padding: '20px' }}>No collections found</p>
            )}
          </div>
          {loading && <div className="loading">Loading...</div>}{" "}
          <div className="modal-footer">
            <div>
              <p>0 product selected</p>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '14px!important', }}>

                <Button variant="primary" onClick={handleStoreData} disabled={loading}>Add</Button>
              </div>
              <div style={{ marginRight: '14px!important', }}>
                <Button onClick={handleStoreData} disabled={loading}>Cancel</Button>

              </div>
            </div>
          </div>
        </div>
      </div >
    </AppProvider>
  );
};

export default Collection;
