import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ShopifyCallback from './components/ShopifyCallback';
import InstallPage from './components/InstallPage';
import AppViewPage from './components/AppViewPage';
import PlanViewPage from './components/PlanViewPage';
import SettingViewPage from './components/SettingViewPage';
import UpdateChargePage from './components/UpdateChargePage';


function App() {
    return (
        <Router>
            <Routes>
                <Route path="/shopify/callback" element={<ShopifyCallback />} />
                <Route path="/shopify/install" element={<InstallPage />} />
                <Route path="/shopify/app_view" element={<AppViewPage />} />
                <Route path="/shopify/plan_view" element={<PlanViewPage />} />
                <Route path="/shopify/setting_view" element={<SettingViewPage />} />
                <Route path="/shopify/updateCharge" element={<UpdateChargePage />} />
            </Routes>
        </Router>
    );
}

export default App;
