import React from "react";

const PulseIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    aria-hidden="true"
    style={{
      verticalAlign: "middle",
      marginRight: "5px",
      display: "inline-block",
    }}
  >
    <circle
      cx="10"
      cy="10"
      r="5"
      fill={color}
      style={{
        animation: "inventory-pulse 2s linear infinite",
        transformOrigin: "center",
      }}
    />
    <circle cx="10" cy="10" r="5" stroke={color} fill={color} strokeWidth="1" />
    <style>
      {`
      @keyframes inventory-pulse {
        0% {
          opacity: 0.75;
        }
        100% {
          opacity: 0;
          transform: scale(2.3);
        }
      }
      `}
    </style>
  </svg>
);

const IconSelect = ({ icon, handleIconChange }) => {
  return (
    <select
      value={icon}
      onChange={(e) => handleIconChange(e.target.value)}
      style={{ width: "100px" }}
      className="select-like-input"
    >
      <option value="">Select Icon</option>
      <option value="<svg width=&quot;20&quot; height=&quot;20&quot; aria-hidden=&quot;true&quot; class=&quot;custom-svg&quot; style=&quot;vertical-align: middle; margin-right: 5px;display:inline-block !important&quot;>
<circle cx=&quot;10&quot; cy=&quot;10&quot; r=&quot;5&quot; fill=&quot;rgb(255,69,0)&quot; style=&quot;animation: inventory-pulse 2s linear infinite; transform-origin: center;&quot;></circle>
<circle cx=&quot;10&quot; cy=&quot;10&quot; r=&quot;5&quot; stroke=&quot;rgb(255,69,0)&quot; fill=&quot;rgb(255,69,0)&quot; stroke-width=&quot;1&quot;></circle>
<style>
      @keyframes inventory-pulse {
        0% {
          opacity: 0.75;
        }
        100% {
          opacity: 0;
          transform: scale(2.3);
        }
      }
    </style>
</svg>">Red Pulse</option><option value="<svg width=&quot;20&quot; height=&quot;20&quot; aria-hidden=&quot;true&quot; class=&quot;custom-svg&quot; style=&quot;vertical-align: middle; margin-right: 5px;display:inline-block !important&quot;>
<circle cx=&quot;10&quot; cy=&quot;10&quot; r=&quot;5&quot; fill=&quot;rgb(238,148,65)&quot; style=&quot;animation: inventory-pulse 2s linear infinite; transform-origin: center;&quot;></circle>
<circle cx=&quot;10&quot; cy=&quot;10&quot; r=&quot;5&quot; stroke=&quot;rgb(238,148,65)&quot; fill=&quot;rgb(238,148,65)&quot; stroke-width=&quot;1&quot;></circle>
<style>
      @keyframes inventory-pulse {
        0% {
          opacity: 0.75;
        }
        100% {
          opacity: 0;
          transform: scale(2.3);
        }
      }
    </style>
</svg>">Orange Pulse</option><option value="<svg width=&quot;20&quot; height=&quot;20&quot; aria-hidden=&quot;true&quot; class=&quot;custom-svg&quot; style=&quot;vertical-align: middle; margin-right: 5px;display:inline-block !important&quot;>
<circle cx=&quot;10&quot; cy=&quot;10&quot; r=&quot;5&quot; fill=&quot;rgb(62, 214, 96)&quot; style=&quot;animation: inventory-pulse 2s linear infinite; transform-origin: center;&quot;></circle>
<circle cx=&quot;10&quot; cy=&quot;10&quot; r=&quot;5&quot; stroke=&quot;rgb(62, 214, 96)&quot; fill=&quot;rgb(62, 214, 96)&quot; stroke-width=&quot;1&quot;></circle>
<style>
      @keyframes inventory-pulse {
        0% {
          opacity: 0.75;
        }
        100% {
          opacity: 0;
          transform: scale(2.3);
        }
      }
    </style>
</svg>">Green Pulse</option><option value="<svg width=&quot;20&quot; height=&quot;20&quot; aria-hidden=&quot;true&quot; class=&quot;custom-svg&quot; style=&quot;vertical-align: middle; margin-right: 5px;display:inline-block !important&quot;>
<circle cx=&quot;10&quot; cy=&quot;10&quot; r=&quot;5&quot; fill=&quot;rgb(0,170,255)&quot; style=&quot;animation: inventory-pulse 2s linear infinite; transform-origin: center;&quot;></circle>
<circle cx=&quot;10&quot; cy=&quot;10&quot; r=&quot;5&quot; stroke=&quot;rgb(0,170,255)&quot; fill=&quot;rgb(0,170,255)&quot; stroke-width=&quot;1&quot;></circle>
<style>
      @keyframes inventory-pulse {
        0% {
          opacity: 0.75;
        }
        100% {
          opacity: 0;
          transform: scale(2.3);
        }
      }
    </style>
</svg>">Blue Pulse</option>
      
      <option value="📦">📦</option>

      <option value="🔴">🔴</option>

      <option value="🟠">🟠</option>

      <option value="🟢">🟢</option>

      <option value="❗️">❗️</option>

      <option value="👀">👀</option>

      <option value="😱">😱</option>

      <option value="😳">😳</option>

      <option value="🤯">🤯</option>

      <option value="🤗">🤗</option>

      <option value="😲">😲</option>

      <option value="🙀">🙀</option>

      <option value="👉🏼">👉🏼</option>

      <option value="👉🏾">👉🏾</option>

      <option value="🏷">🏷</option>

      <option value="🥁">🥁</option>

      <option value="📌">📌</option>
      <option value="🔺">🔺</option>
      <option value="🔊">🔊</option>
      <option value="🌪">🌪</option>
      <option value="💨">💨</option>
      <option value="⚡️">⚡️</option>
      <option value="✨">✨</option>
      <option value="💫">💫</option>
      <option value="🚨">🚨</option>
      
    </select>
  );
};

export default IconSelect;
