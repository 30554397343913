import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { updatePlanId } from '../services/shopifyService';

const UpdateChargePage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        // Extract query parameters from the URL
        const urlParams = new URLSearchParams(location.search);
        const shop = urlParams.get('shop');
        const planId = urlParams.get('planId');
        const charge_id = urlParams.get('charge_id');

        if (shop) {
            updatePlanId(planId, charge_id, shop, navigate);
        } else {
            // Handle case where shop parameter is missing
            console.log('Shop parameter is missing in the URL');
        }
    }, [location.search]);

    return <div>Loading...</div>;
};

export default UpdateChargePage;
