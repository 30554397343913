import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Button, Layout, Text } from '@shopify/polaris';
import { fetchPlans, setPlan } from '../services/shopifyService';
import Check from './../img/check.png';
import "../PlanView.css";

const PlanViewPage = () => {
  const [plans, setPlans] = useState([]);
  const location = useLocation();

  useEffect(() => {

    const fetchPlan = async () => {
      try {
        const response = await fetchPlans();
        setPlans(response);
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
    };

    fetchPlan();
  }, []);

  const handleSubscribe = async (planId) => {
    const urlParams = new URLSearchParams(location.search);
    const shop = urlParams.get('shop');

    const response = await setPlan(planId, shop);

    if (response && response.data && response.data.confirmationUrl) {

      window.top.location.href = response.data.confirmationUrl;
    }
  };

  return (
    <Layout>
      <div className='grid-container'>


        {plans.map((plan) => {
          // Parse plan descriptions from JSON string
          const descriptions = JSON.parse(plan.plan_descriptions);

          console.log(typeof plan.plan_price === 0.00 ? 'inline' : 'flex',)
          return (
            <div className='grid-item' style={{ display: plan.plan_price == 0.00 ? 'inline' : 'flex', }}>
              <Layout.Section oneThird key={plan.id} >
                <div style={{ display: plan.plan_price === 0.00 ? 'inline' : 'flex',height:"100%" }}>
                  <div className='cardTemplate justify-between box c' style={{
                    display: plan.plan_price == 0.00 ? 'flex' : 'block',
                    width: '100%',
                    margin: "10px",
                    borderRadius: "0.25rem",
                    borderWidth: "1px",
                    backgroundColor: "#fff",
                    padding: "3.5rem",
                    boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)"
                  }}>
                    <div className={`ribbon ribbon-top-right ${plan.plan_price == 3.99 ? '' : 'ribbonNone'}`}><span>POPULAR</span></div>
                    <div className="card-section" style={{ width: plan.plan_price === 0.00 ? "150px" : "" }}>
                      <Text variant="headingLg" as="h2">{plan.plan_name}</Text>
                    </div>
                    <div className="card-section">
                      <ul>
                        {/* Render each description */}
                        {Object.entries(descriptions).map(([key, desc], index) => (
                          <li style={{ listStyle: "none", color: "#64748b" }} key={index}> <img src={Check} /> {desc}</li>
                        ))}
                      </ul>
                    </div>
                    {plan.plan_price != 0.00 && (
                      <div className="card-section">
                        <Text variant="headingLg" as="h3">${plan.plan_price}</Text>
                      </div>
                    )}
                    <div className="card-section">
                      <button className="primary" style={{
                        width: "15rem",
                        borderRadius: "0.5rem",
                        backgroundColor: "#047857",
                        padding: "0.5rem",
                        color: "#ffff",

                      }} onClick={() => handleSubscribe(plan.id)}> Start 7-day trial </button>
                    </div>
                  </div>
                </div>
              </Layout.Section>
            </div>
          );
        })}
      </div>
    </Layout >
  );
};

export default PlanViewPage;
